// @flow strict
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles['author']} style={{textAlign:'center'}}>
      <p className={styles['author__bio']}>
        {author.bio}
        <br/>
        <a
          href="/"
          // {getContactHref('twitter', author.contacts.twitter)}
          // className={styles['author__bio-twitter']}
          // rel="noopener noreferrer"
          // target="_blank"
        >
          <strong>{author.name}</strong>
        </a>
      </p>
    </div>
  );
};

export default Author;
