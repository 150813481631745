// @flow strict
import React from 'react';
import styles from './Meta.module.scss';

type Props = {
  date: string,
  slug: string
};

const Meta = ({ date,slug }: Props) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']}> {new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} <span style={{color:'lightgray', fontSize:"xx-small"}}>{slug}</span></p>
  </div>
);

export default Meta;
