// @flow strict
import React from 'react';
import styles from './Content.module.scss';
import Image from '../../Image';

type Props = {
  body: string,
  slug: string,
  youtube: string,
  title: string
};

const Content = ({ body, title, slug, youtube }: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>



    <div style={{margin:"0 2% 2% 2%"}}>
      <div className={styles['youtube']}>
      <iframe width="1120" height="630" src={youtube} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen fs="0"></iframe>
      </div>
    </div>

    <div style={{display:"flex"}}>
    <div style={{width:"100%"}}>
        <Image filename={slug.slice(1)+"1.jpg"} />
      </div>
      <div style={{width:"100%"}}>
      <Image filename={slug.slice(1)+"2.jpg"} />
      </div>
      <div style={{width:"100%"}}>
      <Image filename={slug.slice(1)+"3.jpg"} />
      </div>
      <div style={{width:"100%"}}>
      <Image filename={slug.slice(1)+"4.jpg"} />
      </div>
   </div>
    <div style={{display:"flex"}}>
      <div style={{width:"100%"}}>
      <Image filename={slug.slice(1)+"5.jpg"} />
      </div>
      <div style={{width:"100%"}}>
      <Image filename={slug.slice(1)+"6.jpg"} />
      </div>
      <div style={{width:"100%"}}>
      <Image filename={slug.slice(1)+"7.jpg"} />
      </div>
      <div style={{width:"100%"}}>
      <Image filename={slug.slice(1)+"8.jpg"} />
      </div>
   </div>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
